.login-page {
    background-color: rgb(238, 238, 238);
    height: auto;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-box {
    background-color: white;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 15px -5px black;
  }

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
.message-container {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: center;
    padding: 8px;
    color: red;
}

.message {
    margin: 0;
}
