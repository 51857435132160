body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px auto;
  max-width: 300px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  text-align: center;
  line-height: 20px;
  color: white;
  font-weight: bold;
  transition: width 0.3s ease-in-out;
}
.modal-header-title {
  font-weight: bold;
  text-align: center;
  width: 100%;
  color: "black"; 
}
.modal-dialog.custom-modal {
  background-color: #f1f0f3;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
}

.modal-content {
  background-color: #f1f0f3;
  border-radius: 8px; 
}

.add-data-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #afd1b1; 
  width: 15px; 
  height: 15px;
  border: none; 
  color: white; 
  font-size: 18px; 
  padding: 0px 0px; 
  cursor: pointer; 
  transition: background-color 0.3s ease, transform 0.2s ease; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  vertical-align: top;
}

.add-data-container {
  display: flex;
  align-items: flex-start; 
}
.add-data-button:hover {
  background-color: #7ca081; 
  transform: scale(1.1); 
}

.add-data-button:focus {
  outline: none;
  box-shadow: 0 0 5px 3px rgba(72, 201, 176, 0.6); 
}

.add-data-button:focus-visible {
  outline: 2px solid #4CAF50;
}

.danger-action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f4847c;  
  color: white;               
  font-size: 16px;            
  padding: 4px 4px;         
  border: 1px solid #f4847c;  
  border-radius: 4px;         
  cursor: pointer;           
  transition: background-color 0.3s, transform 0.2s ease;
}

.danger-action-button:hover {
  background-color: #f5645a; 
  transform: scale(1.01);      }

.danger-action-button:focus {
  outline: none;
  box-shadow: 0 0 5px 2px rgba(244, 67, 54, 0.5);  
}

.danger-action-button:disabled {
  background-color: #e67b7b; 
  cursor: not-allowed;
}

.submit-action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #b29ede;  
  color: white;               
  font-size: 16px;            
  padding: 4px 4px;         
  border: 1px solid #b29ede;  
  border-radius: 4px;         
  cursor: pointer;           
  transition: background-color 0.3s, transform 0.2s ease;
}

.submit-action-button:hover {
  background-color: #8367a5; 
  transform: scale(1.01);      }

.submit-action-button:focus {
  outline: none;
  box-shadow: 0 0 5px 2px rgba(117, 54, 241, 0.5);  
}

.submit-action-button:disabled {
  background-color: #c6bcdb; 
  cursor: not-allowed;
}


/* Default text styling */
.text-default {
  font-family: 'Arial', sans-serif;
  font-size: 13px;
  color: #414141; /* Dark gray for text */
  line-height: 1.5; /* Good readability */
  margin: 10px 0; /* Add some spacing around text */
}

/* Heading Style */
.text-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50; /* Darker shade for headings */
  margin-bottom: 15px;
}

/* Subheading Style */
.text-subheading {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #34495e; /* Subtle contrast for subheadings */
  margin-bottom: 10px;
}

/* Paragraph Style */
.text-paragraph {
  font-family: 'Georgia', serif;
  font-size: 16px;
  color: #555; /* Lighter text color for readability */
  line-height: 1.6;
  text-align: justify; /* Justifies text to fill the space */
  margin-bottom: 20px;
}

/* Small Text */
.text-small {
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #888; /* Light gray for small text */
  line-height: 1.4;
  margin-bottom: 5px;
}

/* Code/Preformatted Text */
.text-code {
  font-family: 'Courier New', monospace;
  font-size: 14px;
  color: #e74c3c; /* Red color for code */
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 5px;
}

/* Blockquote */
.text-quote {
  font-family: 'Georgia', serif;
  font-size: 18px;
  color: #34495e;
  border-left: 4px solid #2980b9;
  padding-left: 15px;
  margin: 10px 0;
  font-style: italic;
}

/* Link Styling */
.text-link {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: #2980b9; /* Blue for links */
  text-decoration: none;
}

.text-link:hover {
  text-decoration: underline;
}

/* Strong Text (Bold) */
.text-strong {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #2c3e50;
}

/* Text with emphasis (Italic) */
.text-emphasis {
  font-family: 'Georgia', serif;
  font-size: 16px;
  font-style: italic;
  color: #7f8c8d; /* Gray for emphasized text */
}

.tab {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 10px 10px 0 0;
  background-color: #f0f0f0;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid #ccc;
  border-bottom: none;
  z-index: 1;
}

.active-tab {
  background-color: #ddeddd; 
  color: white;
  border-color: #ccc;
  color: #000;
}

