/* General table layout */
.app-table-wrapper {
    width: 100%;
    overflow-x: auto;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
  
  .app-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1200px;
    font-size: 14px;
  }
  
  /* Table header */
  .app-table thead th {
    font-weight: 600;
    font-size: 15px;
    background-color: #f8f9fa;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  /* Table body rows */
  .app-table tbody td {
    padding: 10px;
    border-top: 1px solid #eee;
    white-space: nowrap;
  }
  
  /* Hover effect */
  .app-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Sticky first column (header + cells) */
  .app-sticky-col {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
    white-space: nowrap;
    min-width: 180px;
  }
  
  .app-sticky-col-header {
    position: sticky;
    left: 0;
    background-color: #f8f9fa;
    z-index: 3;
    white-space: nowrap;
    min-width: 180px;
  }
  
  /* Scrollbar tweaks (optional) */
  .app-table-wrapper::-webkit-scrollbar {
    height: 8px;
  }
  .app-table-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .app-table-wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
  